import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Privacy from '~/components/pages/Privacy'
import SEO from '~/utils/seo'

export default function PrivacyPage({ data, location }: PageProps<GatsbyTypes.PrivacyQuery>) {
    return (
        <>
            <SEO
                title='プライバシーポリシー | Onsen*(オンセン)'
                description='Onsen*関連サービス並びにOnsen*Storeのプライバシーポリシーをご確認ください。'
                robots='all'
                url={location.href}
            />
            <Privacy data={data} />
        </>
    )
}

export const query = graphql`
	query Privacy {
		 microcmsPrivacy {
		    content
		 }
	}
`
