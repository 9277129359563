import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Typography from '~/components/atoms/Typography'
import ServiceCard from '~/components/molecules/ServiceCard'
import CampaignCard from '~/components/molecules/CampaignCard'
import { recommend_title, recommend_description, service_list, campaign_card, container } from './styles'
import { Container } from '~/components/layout/Container'

const ServiceList: React.VFC = ({ ...props }) => {
	const { img_03, img_04 } = useStaticQuery<GatsbyTypes.ServiceListQuery>(graphql`
		query ServiceList {
			img_03: file(relativePath: {eq: "img_03.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    img_04: file(relativePath: {eq: "img_04.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		}
	`)

	return (
		<Container css={container} {...props}>
			<Typography variant='h2' css={recommend_title}>今すぐ体験しましょう</Typography>
			<Typography variant='subtitle1' css={recommend_description}>入浴剤とアプリの一緒がおすすめ。</Typography>
			<ul css={service_list}>
				<li>
					<ServiceCard
						image={img_04.childImageSharp.fluid}
						subtitle={'入浴アプリ｜Onsen*'}
						title={'ぽかぽか、リラックス。専門医が監修した入浴アプリ。'}
						linkText={'無料でアプリを入手する'}
						linkUrl={'/pages/app'}
					/>
				</li>
			</ul>
		</Container>
	)
}

export default ServiceList
