import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const recommend_title = css`
	color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const container = css`
	padding-top: 40px;
	padding-bottom: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
`

export const recommend_description = css`
	margin-top: 20px;

	color: ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const service_list = css`
	margin-top: 20px;

	display: grid;
	gap: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;

		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 40px;
	}
`

export const campaign_card = css`
	margin-top: 20px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`