import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'


export const container = css`
	border-radius: 12px;
	overflow: hidden;
	position: relative;
`

export const text_container = css`
	background: ${palette.common.white};
	padding: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 40px;
	}
`

export const category = css`
	margin-bottom: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-bottom: 20px;
	}
`

export const text_link = css`
	margin-top: 20px;
	color: ${palette.common.white};
	background-color: ${palette.primary.main};

	:hover {
		background-color: ${palette.primary.light};
	}

	@media (min-width: ${breakpoints.pc}px) {
		margin-top: 40px;
	}
`