import React, { FC } from 'react'
import Typography from '~/components/atoms/Typography'
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import Button from '~/components/atoms/Button'

import { container, text_container, category, text_link } from './styles'

type Props = {
	image: FluidObject
	subtitle: string
	title: string
	linkText: string
	linkUrl: string
}

const ServiceCard: FC<Props> = ({ image, subtitle, title, linkText, linkUrl, ...props }) => {
	return (
		<article css={container} {...props}>
			<figure>
				<div><Img fluid={image}/></div>
				<figcaption css={text_container}>
					<Typography css={category} variant='subtitle1' component='h3'
					            color='textSecondary'>{subtitle}</Typography>
					<Typography variant='h4' component='p'
					            color='textPrimary'>{title}</Typography>
					<Link to={linkUrl}>
						<Button css={text_link}>{linkText}</Button>
					</Link>
				</figcaption>
			</figure>
		</article>
	)
}

export default ServiceCard