import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const container = css`
	padding-top: 20px;
	padding-bottom: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
`

export const article = css`
	padding: 20px 0;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 40px 20px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		padding: 40px 0;
	}
`

export const page_title = css`
	margin-top: 5px;
	margin-bottom: -20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
		margin-bottom: -40px;
	}
`

export const bg_primary = css`
	background-color: ${palette.primary.main};
`